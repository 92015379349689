import { CompetitionCardData } from "@wizkidscarnival/wizkids-carnival-commons/models";

export enum ColorType {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Accent = 'Accent'
}

export enum LogoType {
  Small = 's',
  Medium = 'm',
  Large = 'l',
  ExtraLarge = 'xl'
}

export enum DashboardHostingPlatform {
  PARTNER = 'partner',
  WIZ_KIDS_APPS = 'wizkids',
  NONE = 'none'
}

export enum RegistrationChannel {
  SELF = 'self',
  EXTERNAL = 'external'
}

export interface AppLogo {
  url: string;
  width: number;
  height: number;
}

export interface AppConfig {
  name: string;
  partnerType: string;
  domain: string;
  companyId: string;
  companyWebsite: string;
  address: string;
  pinCode: string;
  city: string;
  logo: {
    smallLogo: AppLogo;
    largeLogo: AppLogo;
  };
  color: {
    primary: string;
    secondary: string;
    accent: string;
  };
  supportDetails: {
    email: string;
    phone: string;
  };
  isBrandContestActive: boolean;
  closureMessage: string;
  appStatus: AppStatus,
  redirectingTo: string;
  leagueName: string;
  dashboardHostedOn: DashboardHostingPlatform;
  registrationChannel: RegistrationChannel;
  externalRegistrationDetails?: {
    url: string;
    buttonText?: string;
  }
}

export interface ContestQualificationLevel {
  id: string;
  levelName: string;
  levelNumber: number;
  minimumQualificationLevel: number;
}

export interface ChildData {
  id: string;
  childId: string;
  fullName: string;
  dateOfBirth: string;
  profileImage: string;
  age: number;
  section: string;
  grade: string;
  schoolName: string;
  fieldOfInterest: string[];
  teacherName: string;
  teacherMobileNumber: string;
  contestQualificationLevel: ContestQualificationLevel;
}

export interface SubscriptionData {
  isSubscribed: boolean;
  isPremiumUser: boolean;
  name: string;
  detailedBenefits: string;
  perks: string[];
  subscriptionStartDate: string;
  subscriptionExpiryDate: string;
}

export interface UserData {
  userId: string;
  name: string;
  phoneNumber: string;
  email: string;
  profilePictureUrl: string;
  children: ChildData[];
  subscription: SubscriptionData;
  address: string;
  pinCode: string;
  city: string;
  alternatePhoneNumber: string;
  source: string;
  agentName: string;
}


export interface SidebarState {
  isOpen: boolean;
}

export interface DropdownData {
  value: string;
  isPublic: boolean;
  type: string;
  urlText: string;
}

export interface CalenderDate {
  date: string;
  month: string;
  year: string;
}


export interface ThemeItem {
  imgSrc: string;
  title: string;
  description: string;
}

export interface Theme {
  title: string;
  description: string;
  data: ThemeItem[];
}

export interface Prize {
  first: string,
  second: string,
  third: string,
}

export interface FAQ {
  id: string,
  question: string,
  answer: string
}

export interface CompetitionDetailsData {
  isEnrolled: boolean;
  contestId: string;
  title: string;
  description: string;
  thingsToKnow: string;
  minAge: number;
  maxAge: number;
  level: string;
  amount: number;
  themeGuidelinesPdfLink: string;
  theme: Theme,
  submitDate: CalenderDate;
  resultDate: CalenderDate;
  certificationAndGiftCardDate: CalenderDate;
  publicationDate: CalenderDate;
  prize: Prize;
  FAQ: FAQ[]
}

export interface DetailPageNavItem {
  divId?: string;
  imgUrl: string;
  label: string;
}

export interface ContestType {
  key: string;
  name: string;
}

export type CompetitionCardDetails = CompetitionCardData

export interface RedirectionPageData {
  path: string;
  callbackPath?: string;
  contestName?: string;
}

export interface ToastData {
  isShowing: boolean;
  message: string | {
    response: string;
    nextStep: string;
  };
  isSuccess: boolean;
}


export interface SubscriptionStatus {
  isComboAvailable: boolean,
  isPremiumUser: boolean,
  enrollmentLeft: number,
}

export type FormValidatorResponse = | {
  isValid: true;
} | {
  isValid: false;
  error: string;
}

export interface Option {
  label: string;
  value: string;
}

export enum InputType {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  SELECT = 'select',
  MULTISELECT = 'multiselect',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  DATE = 'date',
  FILE = 'file',
  EMAIL = 'email',
  NUMBER = 'number',
  PASSWORD = 'password',
}

export enum AppStatus {
  IN_DEVELOPMENT = 'IN_DEVELOPMENT',
  IN_MAINTENANCE = 'IN_MAINTENANCE',
  REDIRECTING = 'REDIRECTING',
  LIVE = 'LIVE'
}

export interface ComboData {
  comboCode: string;
  comboName: string;
  discountedPrice: number;
  maximumItemCount: number;
}

export enum PartnerType {
  SCHOOL = 'school',
  BRAND = 'brand',
} 