import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import PartnerLandingPage from "../../../models/partnerLandingPage";

const landingPageUIFlowInitialState: PartnerLandingPage["uiFlow"] = [

]

const landingPageUIFlowSlice = createSlice({
  name: 'landingPageUIFlow',
  initialState: landingPageUIFlowInitialState,
  reducers: {
    setLandingPageUIFlow: (state: PartnerLandingPage["uiFlow"], action: PayloadAction<PartnerLandingPage["uiFlow"]>) => {
      return action.payload;
    }
  }
});

export const { setLandingPageUIFlow } = landingPageUIFlowSlice.actions;

export const selectLandingPageUIFlow = (state: { landingPageUIFlow: PartnerLandingPage["uiFlow"] }) => state.landingPageUIFlow;

export default landingPageUIFlowSlice.reducer;

