import { configureStore } from "@reduxjs/toolkit";

import appConfigReducer from "./slice/appConfig";
import selectedChildReducer from "./slice/selectedChild";
import userDataReducer from "./slice/userData";
import sidebarStateReducer from "./slice/sidebarState";
import toastDataReducer from "./slice/toastData";
import subscriptionStatusReducer from "./slice/subscriptionStatus";
import landingPageUIFlowReducer from "./slice/landingPageUIFlow";

const store = configureStore({
  reducer: {
    // Add reducers here
    appConfig: appConfigReducer,
    selectedChild: selectedChildReducer,
    userData: userDataReducer,
    sidebarState: sidebarStateReducer,
    toastData: toastDataReducer,
    subscriptionStatus: subscriptionStatusReducer,
    landingPageUIFlow: landingPageUIFlowReducer,
  },
});

export default store;
