import React from "react";
import { Spinner } from "react-bootstrap";

import "./style.scss";

interface SectionLoaderProps {
  message?: string | React.ReactNode;
}

const SectionLoader: React.FC<SectionLoaderProps> = ({ message }) => {
  return (
    <div className="d-flex align-items-center justify-content-center w-100 h-100 section-loader">
      <Spinner animation="border" className="text-primary" />
      {message && <div className="fs-3">{message}</div>}
    </div>
  );
};

export default SectionLoader;
