const platformNames = {
  MIS: {
    label: "MIS",
    value: "mis",
  },
  GARFIELD_MOVIE: {
    label: "Garfield",
    value: "garfield",
  },
  MC_DONALD: {
    label: "Mc Donald's",
    value: "mcdonalds",
  },
  CITY_CENTER: {
    label: "City Center Mall, Mangalore",
    value: "citycenter",
  },
  DUCKLINGS: {
    label: "Ducklings",
    value: "ducklings",
  }
}

export default platformNames;