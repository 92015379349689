import { ChildDetailsFormState } from '../pages/account/settings/components/ChildDetailsForm/utils/reducer/ChildFormState'
import { PersonalProfileDetailsFormState } from '../pages/account/settings/components/ParentDetailForm/utils/reducer/PersonalProfileDetailsFormState'
import AxiosClient from './_setup/AxiosClient'
import { convertChildFormDetailsToChildDetailsPayload, convertPersonalFormDetailsToUserDetailsPayload, convertUserResponseDataToUserData } from './acl'

const ProfileService = {
  getProfileDetails: () => AxiosClient.get('/account/self/profile/detail').then(res => {
    if (res.data.response_code === 80) {
      return {
        ...res,
        data: {
          ...res.data,
          data: convertUserResponseDataToUserData(res.data.data)
        }
      }
    } else {
      throw new Error("Error while fetching profile details")
    }
  }),
  getAgents: () => AxiosClient.get('/account/agent/list'),

  getDropdownItems: () => AxiosClient.get('/basic/profile/dropdowns'),

  updateProfileDetails: (userDetails: any) => {
    const newDetails = { ...userDetails.parentDetails }
    newDetails.children = [...userDetails.childDetails]
    return AxiosClient.put('/account/profile/edit', newDetails)
  },
  editChildDetails: (childDetails: ChildDetailsFormState['formState']) => AxiosClient.put('/account/edit/children', { children: [convertChildFormDetailsToChildDetailsPayload(childDetails)] }).then(res => {
    if (res.data.response_code === 80) {
      return {
        ...res,
        data: {
          ...res.data,
          data: convertUserResponseDataToUserData(res.data.data)
        }
      }
    } else {
      throw new Error("Error while updating child/contestant details")
    }

  }),
  editProfile: (formState: PersonalProfileDetailsFormState) => AxiosClient.put('/account/profile/edit', convertPersonalFormDetailsToUserDetailsPayload(formState)).then(res => {
    if (res.data.response_code === 80) {
      return {
        ...res,
        data: {
          ...res.data,
          data: convertUserResponseDataToUserData(res.data.data)
        }
      }
    } else {
      throw new Error("Error while updating profile details")
    }
  }),

  getServiceAvailability: (partnerDomainName: string) => AxiosClient.get(`/account/service_on_pincode?domain=${partnerDomainName}`).then(res => {
    if (res.data.response_code === 80) {
      return {
        ...res,
        data: {
          ...res.data,
          data: {
            ...res.data.data,
            isServiceAvailable: res.data.data.service_available ? true : false
          }
        }
      }
    } else {
      throw new Error("Error while fetching service availability")
    }
  })
}

export default ProfileService
