import { createSlice } from "@reduxjs/toolkit";
import { ChildData } from "../../../models";
import { initialChildState } from "../../../models/initialStates";

interface SelectedChildData {
  childData: ChildData;
}

const selectedChildInitialData = {
  childData: initialChildState
}

export const selectedChildSlice = createSlice({
  name: "selectedChild",
  initialState: selectedChildInitialData,
  reducers: {
    setSelectedChildDataToStore: (state, action) => {
      state.childData = action.payload;
    },
  },
});

export const selectSelectedChildData = (state: { selectedChild: SelectedChildData }) => state.selectedChild.childData;

export const { setSelectedChildDataToStore } = selectedChildSlice.actions;

export default selectedChildSlice.reducer;
