import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppConfig, AppStatus, DashboardHostingPlatform, RegistrationChannel } from "../../../models";

const appConfigInitialState: AppConfig = {
  name: '',
  partnerType: '',
  domain: '',
  companyId: '',
  companyWebsite: '',
  address: '',
  pinCode: '',
  city: '',
  logo: {
    smallLogo: {
      url: '',
      width: 0,
      height: 0
    },
    largeLogo: {
      url: '',
      width: 0,
      height: 0
    }
  },
  color: {
    primary: '',
    secondary: '',
    accent: ''
  },
  supportDetails: {
    email: '',
    phone: ''
  },
  isBrandContestActive: true,
  closureMessage: '',
  appStatus: AppStatus.LIVE,
  redirectingTo: '',
  leagueName: '',
  dashboardHostedOn: DashboardHostingPlatform.NONE,
  registrationChannel: RegistrationChannel.SELF,
  externalRegistrationDetails: {
    url: ''
  }
}

const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState: appConfigInitialState,
  reducers: {
    setAppConfig: (state: AppConfig, action: PayloadAction<AppConfig>) => {
      return {
        ...state,
        ...action.payload
      };
    },
    setPartnerDomain: (state: AppConfig, action: PayloadAction<string>) => {
      return {
        ...state,
        domain: action.payload
      }
    },
  }
});

export const selectAppConfig = (state: { appConfig: AppConfig }) => state.appConfig;
export const selectPartnerLogo = (state: { appConfig: AppConfig }) => state.appConfig.logo;
export const selectPartnerColor = (state: { appConfig: AppConfig }) => state.appConfig.color;
export const selectPartnerSupportDetails = (state: { appConfig: AppConfig }) => state.appConfig.supportDetails;
export const selectPartnerDomain = (state: { appConfig: AppConfig }) => state.appConfig.domain;
export const selectIsBrandContestActive = (state: { appConfig: AppConfig }) => state.appConfig.isBrandContestActive;
export const selectClosureMessage = (state: { appConfig: AppConfig }) => state.appConfig.closureMessage;
export const selectAppStatus = (state: { appConfig: AppConfig }) => state.appConfig.appStatus;
export const selectAppsRedirectionLink = (state: { appConfig: AppConfig }) => state.appConfig.redirectingTo;
export const selectLeagueName = (state: { appConfig: AppConfig }) => state.appConfig.leagueName;
export const selectPartnerType = (state: { appConfig: AppConfig }) => state.appConfig.partnerType;
export const selectDashboardHostedOn = (state: { appConfig: AppConfig }) => state.appConfig.dashboardHostedOn;
export const selectRegistrationChannel = (state: { appConfig: AppConfig }) => state.appConfig.registrationChannel;

export const { setAppConfig, setPartnerDomain } = appConfigSlice.actions;

export default appConfigSlice.reducer;