import React from "react";
import "./style.scss";

import OnboardingSlides from "../../../components/OnboardingSlides";
import HeaderAppLogo from "../../../components/HeaderAppLogo";

type Props = {
  children: React.ReactNode;
};

const AuthLayout = (props: Props) => {
  return (
    <div className="d-flex flex-row auth-layout">
      <OnboardingSlides />
      <div className="auth-form text-center text-md-start">
          <HeaderAppLogo />
        {props.children}
      </div>
    </div>
  );
};

export default AuthLayout;
