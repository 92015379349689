import { RefObject } from "react";
import { CalenderDate } from "../models";

export const isEmailValid = (email: string) => {
  const re =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  return re.test(email);
};

export const isPasswordValid = (password: string) => {
  const re = /((?=.*\d)(?=.*[a-z])\w.{4,}\w)/i;
  return re.test(password);
};

export const isPhoneNumValid = (phoneNum: string) => {
  const re = /^[0-9]+$/;
  return phoneNum.length > 9 && re.test(phoneNum);
};

export const isPinCodeValid = (pinCode: string) => {
  const re = /^[0-9]+$/;
  return pinCode.length === 6 && re.test(pinCode);
}


export const capitalizeEachWordInString = (text: string) => {
  const capitalizedText = text.split(" ");
  let fullName = text;
  try {
    fullName = capitalizedText
      .map((word) => {
        return word[0].toUpperCase() + word.substring(1);
      })
      .join(" ");
  } catch (error) {
    // console.log(error);
  }
  return fullName;
};

export const convertDateToCalendarDate = (date: string): CalenderDate => {
  const inputDate = new Date(date);
  return {
    date: inputDate.getDate().toString(),
    month: inputDate.toLocaleString("default", { month: "long" }),
    year: inputDate.getFullYear().toString(),
  };
}

export const convertCalenderDateToDateString = (date: CalenderDate): string => {
  return `${date.date} ${date.month}, ${date.year}`;
}

/**
 * 
 * @param date Accepts a date string or date object
 * @returns a formatted date string e.g. 01 Jan, 2022
 */
export const formatDateToString = (date: string | Date): string => {
  try {
    const newDate = new Date(date);

    return newDate.toLocaleString("default", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  } catch (error) {
    return "";
  }
}

export const hasDatePassed = (newDate: string, option?: {
  allowTodaysDate?: boolean;
}) => {
  const today = new Date();
  const date = new Date(newDate);
  today.setHours(0, 0, 0, 0);
  date.setHours(0, 0, 0, 0);
  if (option?.allowTodaysDate) {
    return today >= date;
  } else {
    return today > date;
  }
};

export const calculateLuminance = (r: number, g: number, b: number) => {
  // Calculate luminance using the YIQ formula (0.299 * R + 0.587 * G + 0.114 * B)
  return 0.299 * r + 0.587 * g + 0.114 * b;
};

export const constructQueryParamString = (queryParamObjects: Record<string, any>) =>
  Object.entries(queryParamObjects)
    .map(
      ([key, value]) =>
        `${value !== "" ? `&${key}=${encodeURIComponent(value)}` : ""}`,
    )
    .join("");


export const getLocalDateFormat = (createdOn: string) => {
  const localTimeFormat = new Date(createdOn);
  const uploadedDate = localTimeFormat.toLocaleDateString();
  return uploadedDate;
};


export const getFileType = (filename: string) => {
  return filename.split(".").pop();
};

export const isFileValidToUpload = (
  MIMEType = "",
  isCheckingForImage = false,
) => {

  const allowedApplicationType = [
    "msword", //Microsoft word document application type
    "vnd.openxmlformats-officedocument.wordprocessingml.document", //Open word document application type
    "pdf", //pdf application type
    "x-cdf", // CD audio application type
    "x-mpegURL", // iPhone video format.
  ];

  const mimeTypeObject = MIMEType.split("/");

  const fileTypeObject = {
    type: mimeTypeObject[0],
    subtype: mimeTypeObject[1],
  };

  if (isCheckingForImage) {
    return fileTypeObject.type === "image";
  } else if (["image", "video", "audio"].includes(fileTypeObject.type)) {
    return true;
  } else if (fileTypeObject.type === "application") {
    return allowedApplicationType.includes(fileTypeObject.subtype);
  } else {
    return false;
  }
};

export const getAge = (birthDate: string) => {
  const today = new Date();
  const birthDateObj = new Date(birthDate);
  let age = today.getFullYear() - birthDateObj.getFullYear();
  const month = today.getMonth() - birthDateObj.getMonth();
  if (month < 0 || (month === 0 && today.getDate() < birthDateObj.getDate())) {
    age--;
  }
  return age;
}

export const getAllowedDateForDatePicker = (date = "") => {
  if (date) {
    try {
      return new Date(date).toISOString().split("T")[0];
    } catch (error) {
      console.error(error);
    }
  } else {
    return new Date().toISOString().split("T")[0];
  }
};

export const getUploadingFileType = (file: File) => {
  try {
    const fileType = file.type.split("/");
    const type = fileType[0];
    const extension = fileType[1];
    return { type, extension };
  } catch {
    return { type: "", extension: "" };
  }
};


export const getRating = (obtainedScore: number, maxScore: number, maxRating = 5) => {
  const starCount = Math.round((obtainedScore / maxScore) * maxRating);
  return starCount;
};

export const getFirstName = (fullName: string) => {
  if (!fullName) return "";
  return fullName.split(" ")[0];
};

export const getShortMonthName = (month: string) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  try {
    const monthNumber = parseInt(month);
    return monthNames[monthNumber - 1];
  } catch (error) {
    return month
  }
}

export const smoothScroll = (element: RefObject<HTMLDivElement>, offset: number, durationInMs: number = 10) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(function () {
    if (element.current) {
      element.current.scrollLeft += offset;
      scrollAmount += Math.abs(offset);
      if (scrollAmount >= Math.abs(offset) * 10) {
        clearInterval(slideTimer);
      }
    }
  }, durationInMs);
}

export const convertMMDDYYYYToDate = (date: string): Date => {
  const dateArray = date.split("-");
  return new Date(
    parseInt(dateArray[0]),
    parseInt(dateArray[1]) - 1,
    parseInt(dateArray[2].split("T")[0]),
  );
};

export const getQueryParams = (search: string) => {
  const params = new URLSearchParams(search);
  const queryParams: { [key: string]: string } = {};
  params.forEach((value, key) => {
    queryParams[key] = value;
  });
  return queryParams;
};

export const navigateToWizkidsCarnivalApplication = () => {
  const link = "https://app.wizkidscarnival.com/authentication";
  window.open(link, "_self");
};