import aes from 'crypto-js/aes'
import encUtf8 from 'crypto-js/enc-utf8'

const ACCESS_TOKEN = 'accessToken'
const REFRESH_TOKEN = 'refreshToken'
const BASIC_USER_DATA = 'basicUserData'
const PHONE_NUMBER = 'phoneNumber'

const secretKey = process.env.REACT_APP_API_TOKEN_SECRET_KEY!!

const encryptToken = (token: string) => {
  return aes.encrypt(token, secretKey).toString()
}

const decryptToken = (cipherText: string) => {
  const bytes = aes.decrypt(cipherText, secretKey)
  const originalText = bytes.toString(encUtf8)
  return originalText
}

const LocalStorage = {
  set: {
    accessToken: (accessToken: string) => {
      window.localStorage.setItem(ACCESS_TOKEN, encryptToken(accessToken))
    },
    refreshToken: (refreshToken: string) => {
      window.localStorage.setItem(REFRESH_TOKEN, encryptToken(refreshToken))
    },
    basicUserData: (basicData: Record<string, any>) => {
      window.localStorage.setItem(BASIC_USER_DATA, JSON.stringify(basicData))
    },
  },
  get: {
    accessToken: () => {
      try {
        const accessToken = decryptToken(
          window.localStorage.getItem(ACCESS_TOKEN)!!
        )
        return accessToken
      } catch (err) {
        return null
      }
    },
    refreshToken: () => {
      try {
        const refreshToken = decryptToken(window.localStorage.getItem(REFRESH_TOKEN)!!)
        return refreshToken
      } catch (err) {
        return null
      }
    },
    basicUserData: () =>
      JSON.parse(window.localStorage.getItem(BASIC_USER_DATA)!!),
  },
  clear: () => {
    window.localStorage.removeItem(ACCESS_TOKEN)
    window.localStorage.removeItem(REFRESH_TOKEN)
    window.localStorage.removeItem(PHONE_NUMBER)
  },
  clearBasicUserData: () => {
    window.localStorage.removeItem(BASIC_USER_DATA)
  },
}

export default LocalStorage
