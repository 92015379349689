import { ContestSubmissionMode } from "@wizkidscarnival/wizkids-carnival-commons/constants";
import { ChildData, CompetitionCardDetails, UserData } from ".";

export const initialChildState: ChildData = {
  id: "",
  childId: "",
  fullName: "",
  dateOfBirth: '',
  profileImage: "",
  age: 0,
  grade: "",
  schoolName: "",
  fieldOfInterest: [],
  teacherMobileNumber: "",
  contestQualificationLevel: {
    id: "",
    levelName: "",
    levelNumber: 0,
    minimumQualificationLevel: 0,
  },
  section: "",
  teacherName: "",
}

export const initialUserState: UserData = {
  userId: "",
  name: "",
  phoneNumber: "",
  email: "",
  profilePictureUrl: "",
  children: [],
  subscription: {
    isSubscribed: false,
    isPremiumUser: false,
    name: "",
    detailedBenefits: "",
    perks: [],
    subscriptionStartDate: '',
    subscriptionExpiryDate: '',
  },
  address: "",
  pinCode: "",
  city: "",
  agentName: "",
  alternatePhoneNumber: "",
  source: "",
}

export const initialCompetitionCardDetails: CompetitionCardDetails = {
  contestId: "",
  amount: 0,
  img: "",
  level: "",
  ages: "",
  submissionStartDate: "",
  submitByDate: "",
  name: "",
  disabled: false,
  isEnrolled: false,
  themeGuidelinesPDFLink: "",
  isLive: false,
  resultDeclared: false,
  certificateUploaded: false,
  isFileUploaded: false,
  isAFreeContest: false,
  contestType: {
    key: "",
    name: "",
  },
  pinCodes: [],
  certificateAndGiftCardDate: "",
  resultDate: "",
  submissionMode: ContestSubmissionMode.ONLINE,
}