import AxiosClient from "./_setup/AxiosClient";
import { convertUserResponseDataToUserData } from "./acl";

const AuthService = {
  loginOrRegisterWithPhone: {
    OTP: {
      create: (countryCode: string, mobile: string, platform: string) =>
        AxiosClient.post(`/account/create/otp`, {
          country_code: countryCode,
          mobile,
          platform,
        }),
      verify: (mobile: string, otp: string) =>
        AxiosClient.post(`/account/verify/otp`, {
          mobile,
          otp,
        }).then((res) => {
          if (res.data.response_code === 80) {
            return {
              ...res,
              data: {
                ...res.data,
                data: {
                  userData: convertUserResponseDataToUserData(res.data.data),
                  tokens: {
                    accessToken: res.data.data.access,
                    refreshToken: res.data.data.refresh,
                  },
                },
              },
            };
          } else {
            throw new Error("Error verifying OTP");
          }
        }),
      resend: (countryCode: string, mobile: string) => {
        return AxiosClient.post(`/account/resend/otp`, {
          country_code: `+${countryCode}`,
          mobile,
        });
      },
    },
  },
  refreshAccessToken: (refreshToken: string) =>
    AxiosClient.post(`/auth/api/token/refresh`, {
      refresh: refreshToken,
    }),
};

export default AuthService;
