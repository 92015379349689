import AxiosClient from "../_setup/AxiosClient";
import { convertAppConfigResponseToAppConfig } from "./acl";

const AppConfigService = {
  getAppConfig: (partnerName: string) => AxiosClient.get(`/partner/${partnerName}`).then(res => {
    if (res.data.response_code === 80) {
      return {
        ...res,
        data: {
          ...res.data,
          data: convertAppConfigResponseToAppConfig(res.data.data)
        }
      }
    } else {
      throw new Error('Error fetching app config')
    }
  }).catch(error => {
    throw new Error('Error fetching app config')
  })
}

export default AppConfigService