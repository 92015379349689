import React from "react";
import { Spinner } from "react-bootstrap";

import "./style.scss";

interface AppLoaderProps {
  message?: string | React.ReactNode;
}

const AppLoader: React.FC<AppLoaderProps> = ({ message }) => {
  return (
    <div className="loading-screen">
      <Spinner animation="border" />
      {message && <div className="loading-text">{message}</div>}
    </div>
  );
};

export default AppLoader;
