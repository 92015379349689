import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectAppConfig } from "../../utils/store/slice/appConfig";
import { isEmpty } from "lodash";

import "./style.scss";

type Props = {};

const HeaderAppLogo = (props: Props) => {
  const appConfig = useSelector(selectAppConfig);

  const [companyLogo, setCompanyLogo] = useState("");
  const [poweredByLogo, setPoweredByLogo] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const partnerLogo = appConfig.logo;

    if (isEmpty(partnerLogo)) {
      return;
    }

    setCompanyLogo(partnerLogo.largeLogo.url || "/assets/app-logo.svg");
    setPoweredByLogo("/assets/app-logo.svg");
  }, [appConfig]);

  const openPoweredByLink = () => {
    window.open("https://wizkidscarnival.com", "_blank");
  };
  return (
    <div>
      <div className="header-app-logo justify-content-center justify-content-md-start">
        <img
          className={
            companyLogo !== poweredByLogo ? "partner-logo" : "main-logo"
          }
          src={companyLogo}
          alt={appConfig.name}
          onClick={() => navigate("/")}
        />
        {companyLogo !== poweredByLogo && (
          <>
            <div
              style={{ fontSize: "13px" }}
              className="text-secondary text-center"
            >
              Powered By
            </div>
            <img
              className="powered-by-logo"
              src={poweredByLogo}
              alt="WizKidsCarnival"
              onClick={openPoweredByLink}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default HeaderAppLogo;
