import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ToastData } from "../../../models";



const initialToastData: ToastData = {
  isShowing: false,
  message: "",
  isSuccess: true,
};

const toastDataSlice = createSlice({
  name: "toastData",
  initialState: initialToastData,
  reducers: {
    showToast: (state: ToastData, action: PayloadAction<Omit<ToastData, 'isShowing'>>) => {
      state.isShowing = true;
      state.message = action.payload.message;
      state.isSuccess = action.payload.isSuccess;
    },
    hideToast: (state) => {
      state.isShowing = false;
    },
  },
});

export const selectToastData = (state: { toastData: ToastData }) => state.toastData;

export const { showToast, hideToast } = toastDataSlice.actions;

export default toastDataSlice.reducer;
