export const DARK_COLOR_THRESHOLD = 100;
export const SHADOW_COLOR_THRESHOLD = 80;
export const EXTRA_LIGHT_COLOR_THRESHOLD = 0;
export const LIGHT_COLOR_THRESHOLD = -30;

export const DEFAULT_PRIMARY_COLOR = '#a429d9';
export const DEFAULT_PRIMARY_COLOR_EXTRA_LIGHT = '#efcaff56';
export const DEFAULT_PRIMARY_SHADOW_COLOR = "#8112b0";
export const DEFAULT_PRIMARY_COLOR_DARK = "#6f1f9e";

export const DEFAULT_SECONDARY_COLOR = "#05c7f2";
export const DEFAULT_SECONDARY_COLOR_EXTRA_LIGHT = "#c5f2ff56";
export const DEFAULT_SECONDARY_COLOR_DARK = "#009fc2";
export const DEFAULT_SECONDARY_SHADOW_COLOR = "#009fc2";

export const DEFAULT_ACCENT_COLOR = "#f2c705";

export const CompetitionType = {
  live: {
    name: "Current Contests",
    value: "live",
  },
  expired: {
    name: "Expired Contests",
    value: "expired",
  }
}

export const CONTEST_SNAPSHOT_DIV_ID = "contestSnapshotSection_lading_page";
export const THEME_AND_GUIDELINE_DIV_ID = "themeAndGuidelineDivId";
export const HOW_TO_SUBMIT_DIV_ID = "howToSubmitDivId";
export const CONTEST_TIMELINE_DIV_ID = "contestTimelineDivId";

export const LOCAL_STORAGE_KEYS = {
  partnerDomain: "partnerDomain",
  userData: "userData",
}

export const MIN_AGE = 1;
export const MAX_AGE = 100;

export const GRADES = [
  "Yet to Start School",
  "Nursery / Playschool / Pre-School",
  "Montessori 1",
  "Montessori 2",
  "LKG",
  "UKG",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "Post Standard 10",
  "Homeschooled",
  "Others",
];

export const SECTIONS = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "Not Applicable",
];