import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SubscriptionStatus } from "../../../models";

const initialSubscriptionStatus: SubscriptionStatus = {
  isComboAvailable: false,
  isPremiumUser: false,
  enrollmentLeft: 0,
};

const subscriptionStatusSlice = createSlice({
  name: "subscriptionStatus",
  initialState: initialSubscriptionStatus,
  reducers: {
    setSubscriptionState: (state: SubscriptionStatus, action: PayloadAction<SubscriptionStatus>) => {
      state.isComboAvailable = action.payload.isComboAvailable;
      state.isPremiumUser = action.payload.isPremiumUser;
      state.enrollmentLeft = action.payload.enrollmentLeft;
    },
  },
});

export const selectSubscriptionStatus = (state: { subscriptionStatus: SubscriptionStatus }) => state.subscriptionStatus;

export const selectIsComboAvailable = (state: { subscriptionStatus: SubscriptionStatus }) =>
  state.subscriptionStatus.isComboAvailable;

export const selectIsPremiumUser = (state: { subscriptionStatus: SubscriptionStatus }) =>
  state.subscriptionStatus.isPremiumUser;

export const selectEnrollmentLeft = (state: { subscriptionStatus: SubscriptionStatus }) =>
  state.subscriptionStatus.enrollmentLeft;

export const { setSubscriptionState } = subscriptionStatusSlice.actions;

export default subscriptionStatusSlice.reducer;
