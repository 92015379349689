import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserData } from "../../../models";
import { initialUserState } from "../../../models/initialStates";

export const userDataSlice = createSlice({
  name: "userData",
  initialState: initialUserState,
  reducers: {
    setUserData: (state: UserData, action: PayloadAction<UserData>) => ({ ...state, ...action.payload }),
    resetUserData: (state) => {
      Object.assign(state, initialUserState);
    },
  },
});

export const selectUserData = (state: { userData: UserData }) => state.userData;
export const selectChildrenData = (state: { userData: UserData }) => state.userData.children
export const selectSubscriptionData = (state: { userData: UserData }) => state.userData.subscription;

export const { setUserData, resetUserData } = userDataSlice.actions;

export default userDataSlice.reducer;
